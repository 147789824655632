import React from 'react'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import './solution.css'
import img from '../../assets/Image.png'
import {FiArrowUpRight} from 'react-icons/fi'


function Solution() {
  return (
    <div>
        <Header />
        <div className='solution-container'> 
        <h1 className="discover-style style-solutions animate__animated animate__fadeInDown text-align-center" style={{fontSize:"16.6vw"}}>SOLUTIONS</h1>
        <div className="solution-img">
        <img src="https://xrv-xrc.s3.ap-south-1.amazonaws.com/XRC_Homepage/homepage_contents/dash.webp" alt="" width="35%" className='img-top'  />
        <img src={img} alt="" width="40%"  className='solution-img-bg' />
        </div>
        <div className='row container-content'>
        <div className='col-md-6 d-flex flex-column justify-content-center'>
            <p className='solution-number'>01</p>
            <h3 className='solution-title'>Hyper-realistic wearables</h3>
            <p className='solution-description text-justify'>Our expert craftmanship and powerful set of tools enable us to create high quality digital wearables suitable to any AR/VR environment, 3D avatar and more.</p>
            <a href='https://wrbl.xrcouture.com/' target="_blank" className='solution-content-button'>Create <FiArrowUpRight /></a>
        </div>   
        <div className='col-md-6 d-flex flex-column justify-content-center align-items-center justify-items-center'>
            <img src="https://xrv-xrc.s3.ap-south-1.amazonaws.com/XRC_Homepage/homepage_contents/01.webp" className="image-content" style={{borderRadius:"10px"}} />
        </div>   
        </div>
        <div className='row container-content'>
        <div className='col-md-6 order-1 d-flex order-md-0 flex-column justify-content-center align-items-center'>
            <img src="https://xrv-xrc.s3.ap-south-1.amazonaws.com/XRC_Homepage/homepage_contents/02.webp" className="image-content" />
        </div> 
        <div className='col-md-6 order-0 d-flex flex-column  order-md-1 justify-content-center'>
            <p className='solution-number'>02</p>
            <h3 className='solution-title'>Interoperability</h3>
            <p className='solution-description'>Create and sell digital wearables from your website that can be used across multiple metaverse platforms, online games, social media platforms and blockchains.</p>
            <a href='https://forms.gle/jLWGeCcYPw7CaAgAA ' target="_blank" className='solution-content-button'>Book a demo <FiArrowUpRight /></a>
        </div>   
        </div>
        <div className='row container-content'>
        <div className='col-md-6 d-flex flex-column justify-content-center'>
            <p className='solution-number'>03</p>
            <h3 className='solution-title'>Metaverse Experience</h3>
            <p className='solution-description'>Create experiential and interactive spaces on the metaverse that allows you to increase awareness and deepen relationship with existing customers and tap into a new generation of potential customers. On platforms such as: Spatial, Roblox, Decentraland, etc.</p>
            <a href='https://calendly.com/betsyxrc/' target="_blank" className='solution-content-button'>Talk to us <FiArrowUpRight /></a>
        </div>   
        <div className='col-md-6 d-flex flex-column justify-content-center align-items-center'>
            <img src="https://xrv-xrc.s3.ap-south-1.amazonaws.com/XRC_Homepage/homepage_contents/03.webp" className="image-content" />
        </div>   
        </div>
        </div>
        <Footer />
    </div>
  )
}

export default Solution;