import React, { useEffect, useRef, useState } from 'react'
import {
  bootstrapCameraKit,
  createMediaStreamSource,
  Transform2D
} from '@snap/camera-kit'

import { CgClose } from "react-icons/cg";
import { PuffLoader } from "react-spinners"

import './section2.css'

const video1 = 'https://xrv-xrc.s3.ap-south-1.amazonaws.com/XRC_Homepage/homepage_contents/CrystalTrench.mp4'
const video2 = 'https://xrv-xrc.s3.ap-south-1.amazonaws.com/XRC_Homepage/homepage_contents/Hijikata-preview.mp4'
const video3 = 'https://xrv-xrc.s3.ap-south-1.amazonaws.com/XRC_Homepage/homepage_contents/Ryoma-preview.mp4'
const qrnew1 = 'https://xrv-xrc.s3.ap-south-1.amazonaws.com/XRC_Homepage/homepage_contents/snapcode.webp'
const qrnew2 = 'https://xrv-xrc.s3.ap-south-1.amazonaws.com/XRC_Homepage/homepage_contents/hijikata_snapcode.png'
const qrnew3 = 'https://xrv-xrc.s3.ap-south-1.amazonaws.com/XRC_Homepage/homepage_contents/ryoma_snapcode.png'

// import AnimatedText from 'react-animated-text-content';

function Section2() {

  const divRef = useRef(null)
  let playedOnce = useRef(false)

  useEffect(() => {
    if (!playedOnce.current) {

      const cameraKitSetup = async () => {

        const cameraKit = await bootstrapCameraKit({ apiToken: 'eyJhbGciOiJIUzI1NiIsImtpZCI6IkNhbnZhc1MyU0hNQUNQcm9kIiwidHlwIjoiSldUIn0.eyJhdWQiOiJjYW52YXMtY2FudmFzYXBpIiwiaXNzIjoiY2FudmFzLXMyc3Rva2VuIiwibmJmIjoxNzEyMzA3ODI5LCJzdWIiOiIyM2YwMzgxNi00OTllLTRjMzctODE3OC01ODI3ZDRiYWRmNzB-U1RBR0lOR344MDc2YjUwZS1kZWM3LTQ4YTgtYmU2My1kNDYzYTI1ZGVkNzgifQ.ig-EdXc6uPH3gdWszWCdpvXFLTF8Kx1MqnwZm6FbzvI' })
        const { lenses } = await cameraKit.lensRepository.loadLensGroups(['6fc2d4bd-6c66-432f-a5ae-e4d3a3b043ca'])
        console.log(lenses)

        let mediaStream;
        let session = await cameraKit.createSession()


        const toggleLensBtn1 = (data) => {
          let lens = document.getElementById("try-lens-1")
          if (data) {
            lens.disabled = true
            lens.style.cursor = "not-allowed"
            lens.style.opacity = "0.5"
          }
          else {
            lens.disabled = false
            lens.style.cursor = "pointer"
            lens.style.opacity = "1"
          }

          const lensCont = document.getElementById('lens-cont-1');
          const canvasToRemove = lensCont.querySelector('canvas');
          if (canvasToRemove) {
            lensCont.removeChild(canvasToRemove);

            document.getElementById('try-lens-1').style.display = "flex"
            document.getElementById('close-lens-1').style.display = "none"
          }
        }


        const toggleLensBtn2 = (data) => {
          let lens = document.getElementById("try-lens-2")
          if (data) {
            lens.disabled = true
            lens.style.cursor = "not-allowed"
            lens.style.opacity = "0.5"
          }
          else {
            lens.disabled = false
            lens.style.cursor = "pointer"
            lens.style.opacity = "1"
          }

          const lensCont = document.getElementById('lens-cont-2');
          const canvasToRemove = lensCont.querySelector('canvas');
          if (canvasToRemove) {
            lensCont.removeChild(canvasToRemove);

            document.getElementById('try-lens-2').style.display = "flex"
            document.getElementById('close-lens-2').style.display = "none"
          }
        }


        const toggleLensBtn3 = (data) => {
          let lens = document.getElementById("try-lens-3")
          if (data) {
            lens.disabled = true
            lens.style.cursor = "not-allowed"
            lens.style.opacity = "0.5"
          }
          else {
            lens.disabled = false
            lens.style.cursor = "pointer"
            lens.style.opacity = "1"
          }

          const lensCont = document.getElementById('lens-cont-3');
          const canvasToRemove = lensCont.querySelector('canvas');
          if (canvasToRemove) {
            lensCont.removeChild(canvasToRemove);

            document.getElementById('try-lens-3').style.display = "flex"
            document.getElementById('close-lens-3').style.display = "none"
          }
        }



        // Lens1
        const lensBtn1 = document.getElementById('try-lens-1');
        const closeBtn1 = document.getElementById('close-lens-1');

        lensBtn1.addEventListener('click', async () => {
          try {

            toggleLensBtn2(true)
            toggleLensBtn3(true)

            lensBtn1.style.display = "none"

            const loadingCont = document.getElementById("lens-loading-1")
            loadingCont.style.display = "flex"


            session.applyLens(lenses[2]).then(() => {
              setTimeout(() => {

                console.log("running")
                const newCanvas = document.createElement('canvas');
                const lensCont1 = document.getElementById('lens-cont-1');
                lensCont1.appendChild(newCanvas);

                newCanvas.replaceWith(session.output.live)

                setTimeout(() => {

                  loadingCont.style.display = "none"
                  closeBtn1.style.display = "flex"
                  toggleLensBtn2(false)
                  toggleLensBtn3(false)

                }, 3000);
              }, 1000);
            })

            mediaStream = await navigator.mediaDevices.getUserMedia({ video: true, })
            console.warn("mediaStream", mediaStream)


            let source = createMediaStreamSource(mediaStream, {
              transform: Transform2D.MirrorX,
              cameraType: 'front'
            })

            await session.setSource(source)
            console.log(divRef.current.clientWidth, divRef.current.clientHeight)
            session.source.setRenderSize(divRef.current.clientWidth, divRef.current.clientHeight + 25)
            session.play()
          }
          catch {
            console.error('Error accessing camera:');
          }
        });

        closeBtn1.addEventListener('click', async () => {
          try {
            if (session) {
              session.pause()
              const lensCont1 = document.getElementById('lens-cont-1');
              const canvasToRemove = lensCont1.querySelector('canvas');
              if (canvasToRemove) {
                lensCont1.removeChild(canvasToRemove);

                lensBtn1.style.display = "flex"
                closeBtn1.style.display = "none"
              }

            }
          }
          catch {
            console.error('Error closing:');
          }
        });



        // Lens2
        const lensBtn2 = document.getElementById('try-lens-2');
        const closeBtn2 = document.getElementById('close-lens-2');

        lensBtn2.addEventListener('click', async () => {
          try {
            toggleLensBtn1(true)
            toggleLensBtn3(true)


            lensBtn2.style.display = "none"

            const loadingCont = document.getElementById("lens-loading-2")
            loadingCont.style.display = "flex"


            session.applyLens(lenses[1]).then(() => {
              setTimeout(() => {
                console.log("running")
                const newCanvas = document.createElement('canvas');
                const lensCont2 = document.getElementById('lens-cont-2');
                lensCont2.appendChild(newCanvas);

                newCanvas.replaceWith(session.output.live)
                setTimeout(() => {

                  loadingCont.style.display = "none"
                  closeBtn2.style.display = "flex"

                  toggleLensBtn1(false)
                  toggleLensBtn3(false)
                }, 3000);
              }, 1000);
            })

            mediaStream = await navigator.mediaDevices.getUserMedia({ video: true, })
            console.warn("mediaStream", mediaStream)


            let source = createMediaStreamSource(mediaStream, {
              transform: Transform2D.MirrorX,
              cameraType: 'front'
            })

            await session.setSource(source)
            console.log(divRef.current.clientWidth, divRef.current.clientHeight)
            session.source.setRenderSize(divRef.current.clientWidth, divRef.current.clientHeight + 25)
            session.play()
          }
          catch {
            console.error('Error accessing camera:');
          }
        });

        closeBtn2.addEventListener('click', async () => {
          try {
            if (session) {
              session.pause()
              const lensCont2 = document.getElementById('lens-cont-2');
              const canvasToRemove = lensCont2.querySelector('canvas');
              if (canvasToRemove) {
                lensCont2.removeChild(canvasToRemove);

                lensBtn2.style.display = "flex"
                closeBtn2.style.display = "none"
              }

            }
          }
          catch {
            console.error('Error closing:');
          }
        });



        // Lens3
        const lensBtn3 = document.getElementById('try-lens-3');
        const closeBtn3 = document.getElementById('close-lens-3');

        lensBtn3.addEventListener('click', async () => {
          try {
            toggleLensBtn1(true)
            toggleLensBtn2(true)


            lensBtn3.style.display = "none"

            const loadingCont = document.getElementById("lens-loading-3")
            loadingCont.style.display = "flex"


            session.applyLens(lenses[0]).then(() => {
              setTimeout(() => {
                console.log("running")
                const newCanvas = document.createElement('canvas');
                const lensCont3 = document.getElementById('lens-cont-3');
                lensCont3.appendChild(newCanvas);

                newCanvas.replaceWith(session.output.live)
                setTimeout(() => {

                  loadingCont.style.display = "none"
                  closeBtn3.style.display = "flex"

                  toggleLensBtn1(false)
                  toggleLensBtn2(false)
                }, 3000);
              }, 1000);
            })

            mediaStream = await navigator.mediaDevices.getUserMedia({ video: true, })
            console.warn("mediaStream", mediaStream)


            let source = createMediaStreamSource(mediaStream, {
              transform: Transform2D.MirrorX,
              cameraType: 'front'
            })

            await session.setSource(source)
            console.log(divRef.current.clientWidth, divRef.current.clientHeight)
            session.source.setRenderSize(divRef.current.clientWidth, divRef.current.clientHeight + 25)
            session.play()
          }
          catch {
            console.error('Error accessing camera:');
          }
        });

        closeBtn3.addEventListener('click', async () => {
          try {
            if (session) {
              session.pause()
              const lensCont3 = document.getElementById('lens-cont-3');
              const canvasToRemove = lensCont3.querySelector('canvas');
              if (canvasToRemove) {
                lensCont3.removeChild(canvasToRemove);

                lensBtn3.style.display = "flex"
                closeBtn3.style.display = "none"
              }

            }
          }
          catch {
            console.error('Error closing:');
          }
        });

      }

      cameraKitSetup()
      playedOnce.current = true
    }
  }, [])


  return (
    <div className='try-on-ar'>

      <div className='w-100 d-flex justify-content-center'>
        <h1 className="discover-style animate__animated animate__fadeInDown">TRY ON AR</h1>
      </div>

      <div className='row cards-overall-container mt-5'>

        <div className='col-sm-4 d-flex justify-content-center mobile-ar-card'>
          <div className='card'>
            <div className='ar-card'>

              <div className='img-container' id='lens-cont-1'>
                <a rel="noreferrer" target={'_blank'} href='https://lens.snapchat.com/1257e71f11094ab99530cd5cd635b85d'>
                  <img style={{ zIndex: "1000" }} src={qrnew3} alt="" className="qr" />
                </a>
                <video src={video3} autoPlay loop muted playsInline className="snap-img" />

                <h5 className='card-text about-subtitle'>Sega - Ryoma</h5>

                <button id="try-lens-1" className='try-lens-btn'>
                  <img src='https://xrv-xrc.s3.ap-south-1.amazonaws.com/XRC_Homepage/homepage_contents/camera.png' alt=''></img>
                  Try Lens
                </button>

                <div id="lens-loading-1" className='lens-loading'>
                  <PuffLoader color="#fff" />
                  <span style={{ marginTop: "1rem" }}>Enabling Camera <br /> and Applying Lens...</span>
                </div>

                <button id="close-lens-1" className='close-lens' >
                  <CgClose color={"#ffffff"} size={15} />
                </button>
              </div>

            </div>
          </div>
        </div>

        <div className='col-sm-4 d-flex justify-content-center'>
          <div className='card'>
            <div className='ar-card'>

              <div className='img-container' id='lens-cont-2'>
                <a rel="noreferrer" target={'_blank'} href='https://lens.snapchat.com/435beccb14744014963d4661abd70fab'>
                  <img style={{ zIndex: "1000" }} src={qrnew1} alt="" className="qr" />
                </a>
                <video ref={divRef} src={video1} autoPlay loop muted playsInline className="snap-img" />
                <h5 className='about-subtitle card-text'>Cristal Trench</h5>

                <button id="try-lens-2" className='try-lens-btn'>
                  <img src='https://xrv-xrc.s3.ap-south-1.amazonaws.com/XRC_Homepage/homepage_contents/camera.png' alt=''></img>
                  Try Lens
                </button>

                <div id="lens-loading-2" className='lens-loading'>
                  <PuffLoader color="#fff" />
                  <span style={{ marginTop: "1rem" }}>Enabling Camera <br /> and Applying Lens...</span>
                </div>

                <button id="close-lens-2" className='close-lens' >
                  <CgClose color={"#ffffff"} size={15} />
                </button>

              </div>

            </div>
          </div>
        </div>

        <div className='col-sm-4 d-flex justify-content-center'>
          <div className='card'>
            <div className='ar-card'>

              <div className='img-container' id='lens-cont-3'>
                <a rel="noreferrer" target={'_blank'} href='https://lens.snapchat.com/b9ec83e802404cc59f1d86c0cc12bc60'>
                  <img style={{ zIndex: "1000" }} src={qrnew2} alt="" className="qr" />
                </a>
                <video src={video2} autoPlay loop muted playsInline className="snap-img" />
                <h5 className='card-text text-bold about-subtitle'>Sega - Hijikata</h5>

                <button id="try-lens-3" className='try-lens-btn'>
                  <img src='https://xrv-xrc.s3.ap-south-1.amazonaws.com/XRC_Homepage/homepage_contents/camera.png' alt=''></img>
                  Try Lens
                </button>

                <div id="lens-loading-3" className='lens-loading'>
                  <PuffLoader color="#fff" />
                  <span style={{ marginTop: "1rem" }}>Enabling Camera <br /> and Applying Lens...</span>
                </div>

                <button id="close-lens-3" className='close-lens' >
                  <CgClose color={"#ffffff"} size={15} />
                </button>

              </div>

            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Section2


{/* <AnimatedText
        type="chars" // animate words or chars
        animation={{
            x: '200px',
            y: '200px',
            scale: 1.1,
            ease: 'ease-in-out',
        }}
        animationType="wave"
        interval={0.6}
        duration={.5  }
        tag="p"
        className="animated-paragraph discover-style"
        includeWhiteSpaces
        threshold={0.3}
        rootMargin="20%"
        > */}
{/* <div className='discover-style text-center'>TRY  ON AR</div> */ }
{/* </AnimatedText> */ }