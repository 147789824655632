import React from "react";
import { useGLTF } from "@react-three/drei";

export function Sandbox(props) {
  const { nodes, materials } = useGLTF("/sandbox.glb");
  return (
    <group {...props} dispose={null}>
      <group position={[0,0,0]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.mesh001.geometry}
          material={materials["LeftFoot-Material.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.mesh001_1.geometry}
          material={materials["Hip-Material.002"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.mesh001_2.geometry}
          material={materials["Belly-Material.002"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.mesh001_3.geometry}
          material={materials["ChestA2-Material.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.mesh001_4.geometry}
          material={materials["Head_A-Material.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.mesh001_5.geometry}
          material={materials["LeftArm-Material.002"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.mesh001_6.geometry}
          material={materials["LeftForeArm-Material.002"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.mesh001_7.geometry}
          material={materials["LeftHand-Material.002"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.mesh001_8.geometry}
          material={materials["rightHandCloned-Material.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.mesh001_9.geometry}
          material={materials["right cloned fore arm-Material.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.mesh001_10.geometry}
          material={materials["RightHand-Material.002"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.mesh001_11.geometry}
          material={materials["RightThigh-Material.002"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.mesh001_12.geometry}
          material={materials["RightLeg-Material.002"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.mesh001_13.geometry}
          material={materials["Rightfoot-Material.003"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.mesh001_14.geometry}
          material={materials["LeftThigh-Material.002"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.mesh001_15.geometry}
          material={materials["LeftLeg-Material.002"]}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/sandbox.glb");
